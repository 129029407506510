import httpClient from "../utils/axios"

import {
    handleServiceResponse,
    handleServiceError,
} from '../utils/serviceResponseHelper'

const getServiceConfig = async serviceId => {
    return httpClient.get(`${window.config.DOCUMENT_API_URL}/v1/serviceconfiguration/get?serviceId=${serviceId}`)
        .then(response => handleServiceResponse(response),
            error => handleServiceError(error))
}

export default {
    getServiceConfig
}