import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import EditableRegionForm from '../../../components/editableRegionForm'
import AdminBackdrop from '../../../components/adminBackdrop'
import AdminPageHeader from '../../../components/adminPageHeader'

import {
  getEditableRegion,
  updateEditableRegion,
  getMergeFields,
} from '../../../services/editableregion.service'
import { useService } from '../../../contexts/serviceContext'

const EditableRegionEdit = () => {
  let history = useHistory()
  let { id } = useParams()
  const { serviceId } = useService()

  const [isLoading, setIsLoading] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [editableRegion, setEditableRegion] = useState(null)
  const [mergeFields, setMergeFields] = useState(null)

  useEffect(() => {
    getMergeFields().then(res => setMergeFields(res))
    getEditableRegion(parseInt(id)).then(result => setEditableRegion(result))
  }, [id, serviceId])

  useEffect(() => {
    setIsLoading(!mergeFields || !editableRegion)
  }, [mergeFields, editableRegion])

  const onSubmit = updatedObj => {
    setIsUpdating(true)
    updateEditableRegion(updatedObj).then(() => {
      setIsUpdating(false)
      history.push('/admin/editable')
    })
  }

  return (
    <>
      <AdminBackdrop show={isLoading || isUpdating} />
      {!isLoading && !isUpdating && editableRegion ? (
        <>
          <AdminPageHeader
            title={'Update Editable'}
            navActionLabel='Return to editables'
            navActionPath='/admin/editable'
          />
          <EditableRegionForm
            editableRegion={editableRegion}
            mergeFields={mergeFields}
            currentEditables={[]}
            onSubmit={onSubmit}
          />
        </>
      ) : null}
    </>
  )
}

export default EditableRegionEdit
