import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import notificationReducer from './notification.reducer'
import brandingReducer from './branding.reducer'
import serviceReducer from './service.reducer'

export default combineReducers({
  auth: authReducer,
  branding: brandingReducer,
  notifications: notificationReducer,
  service: serviceReducer,
})
