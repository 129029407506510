import React, { useEffect, useRef, useState } from 'react'
import AdminCard from './adminCard'
import { useService } from '../contexts/serviceContext'

const EditableRegionPrePopulate = ({
  html,
  htmlChanged
}) => {

  const editorWrapperRef = useRef()
  const editorRef = useRef()

  const onChange = () => editorChanged()

  const editorChanged = () => {
    let $ = window.jQuery
    const editor = $(editorRef.current).data('kendoEditor')
    if (editor) htmlChanged(editor.value())
  }

  const { serviceConfig } = useService()

  useEffect(() => {
    let $ = window.jQuery
    $(editorRef.current).kendoEditor({
      stylesheets: [
        `/css/editor/font-family-${serviceConfig.fontFamily}.css`,
        `/css/editor/font-size-${serviceConfig.fontSize}.css`
      ],
      tools: [
        'bold',
        'italic',
        'underline',
        'insertUnorderedList',
        'insertOrderedList',
        'indent',
        'outdent'
      ],
      change: onChange,
      select: onChange,
      execute: onChange,
      paste: onChange,
    })
    var editor = $(editorRef.current).data('kendoEditor')
    editor.value(html)
    editorWrapperRef.current.style.display = 'block'
  }, [])

  return (
    <AdminCard title='Pre-Populate Text'>
      <div ref={editorWrapperRef} style={{ display: 'none' }}>
        <textarea ref={editorRef}></textarea>
      </div>
    </AdminCard>
  )
}

export default EditableRegionPrePopulate
