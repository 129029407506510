import { dateTimeFormatOptionsConstants } from '../constants/datetime.constants'
export const getLocalisedDateTimeStr = (dateTime, format, nullText) => {
  if (dateTime === undefined || dateTime === null) {
    if (nullText != null) return nullText
    return ''
  }
  if (!isValidDate(dateTime)) return null
  const date = new Date(dateTime)
  let options = dateTimeFormatOptionsConstants.DEFAULT_DATE_TIME
  if (format !== undefined && format !== null) {
    return date.toLocaleDateString(undefined, format)
  }
  // TODO
  // need to find a way to pass instead of 'undefined'
  // the right location (like 'en-GB') depending on the timezone
  // 'undefined' work when this is not specified
  return date.toLocaleDateString(undefined, options)
}
export const isValidDate = date => {
  const dateObject = date ? new Date(date) : null
  return dateObject instanceof Date && !isNaN(dateObject.valueOf())
}
export const getEndOfDay = date => {
  if (!date || !isValidDate(date)) return null
  var end = new Date(date)
  end.setHours(23, 59, 59, 999)
  return end
}
export const getStartOfDay = date => {
  if (!date || !isValidDate(date)) return null
  var start = new Date(date)
  start.setHours(0, 0, 0, 0)
  return start
}
export const getMidOfDay = date => {
  if (!date || !isValidDate(date)) return null
  var start = new Date(date)
  start.setHours(12, 0, 0, 0)
  return start
}
export const isBeforeToday = date => {
  if (!date || !isValidDate(date)) return false
  var dateToCheck = new Date(date)
  var now = new Date()
  return dateToCheck < now
}
export const isToday = date => {
  const dateToday = new Date()
  return (
    date.getDate() == dateToday.getDate() &&
    date.getMonth() == dateToday.getMonth() &&
    date.getFullYear() == dateToday.getFullYear()
  )
}
export const addDays = (date, noOfDays) => {
  if (!date || !noOfDays || !isValidDate(date)) return null
  const newDate = new Date(Number(date))
  newDate.setDate(date.getDate() + noOfDays)
  return newDate
}
export const isWithinWarningHours = (date, warningHours) => {
  if (!date || !isValidDate(date)) return false
  var dateToCheck = new Date(date)
  var targetDate = new Date(date)
  targetDate.setHours(targetDate.getHours() - warningHours)
  var now = new Date()
  return now > targetDate && now <= dateToCheck
}
export const isValidDuration = duration => {
  if (!duration) return false
  return /^(([0-9]+.)?[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{7})/.test(duration)
}
export const durationIncludesDays = duration => {
  if (!duration || !isValidDuration(duration)) return null
  return /^([0-9]+.[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{7})/.test(duration)
}
export const getDays = duration => {
  if (!duration && !isValidDuration(duration)) return null
  if (durationIncludesDays(duration)) {
    return splitDurationToArray(duration)[0]
  } else {
    return 0
  }
}
export const getHours = duration => {
  if (!duration || !isValidDuration(duration)) return null
  if (durationIncludesDays(duration)) {
    return splitDurationToArray(duration)[1]
  } else {
    return splitDurationToArray(duration)[0]
  }
}
export const getMins = duration => {
  if (!duration || !isValidDuration(duration)) return null
  if (durationIncludesDays(duration)) {
    return splitDurationToArray(duration)[2]
  } else {
    return splitDurationToArray(duration)[1]
  }
}
const splitDurationToArray = duration => {
  if (!duration || !isValidDuration(duration)) return null
  try {
    const daysHoursMinsSec = duration.split(new RegExp('[:.]', 'g'))
    return daysHoursMinsSec.map(x => parseInt(x))
  } catch (err) {
    console.error(err)
  }
}
export const formatDateQueryString = queryString => {
  queryString = queryString.replace(/[^0-9a-z\-\?\&\=]/gi, '') //fix for IE crash removes non essential chars
  return queryString
}
export const formatDateForQueryString = date => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const formattedDay = day < 10 ? `0${day}` : day
  const formattedMonth = month < 10 ? `0${month}` : month
  return `${date.getFullYear()}-${formattedMonth}-${formattedDay}`
}
export function toShortFormat(date) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const monthName = monthNames[monthIndex]
  const year = date.getFullYear()
  if (!day || !monthName || !year) return ``
  return `${day} ${monthName} ${year}`
}
export const convertTimespanToDateTime = timeSpan => {
  var date = new Date()
  var hourStr = timeSpan.substring(0, 2)
  var hours = Number(hourStr)
  var minStr = timeSpan.substring(3, 5)
  var mins = Number(minStr)
  date.setHours(hours)
  date.setMinutes(mins)
  date.setMilliseconds(0)
  return date
}
export const convertDateTimeToTimespan = dateTime => {
  var hours = dateTime.getHours()
  var mins = dateTime.getMinutes()
  var result =
    String(hours).padStart(2, '0') + ':' + String(mins).padStart(2, '0')
  return result
}
export const convertDayOfWeekToString = dayOfWeek => {
  // Blame MS for 0=Sunday.
  switch (dayOfWeek) {
    case 0:
      return 'Sunday'
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
  }
}

