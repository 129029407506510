import React, { useState } from 'react'
import {
  Typography,
  Select,
  MenuItem,
  Button,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import EditableDialogWrapper from './editableDialogWrapper'
import PropTypes from 'prop-types'

const DropdownDialog = ({
  title,
  description,
  open,
  onConfirm,
  onReset,
  onClose,
  currentValue,
  dropdownItems,
}) => {
  const [selected, setSelected] = useState(currentValue)
  return (
    <EditableDialogWrapper title={title} open={open} onClose={onClose}>
      <DialogContent dividers>
        {description && <Typography gutterBottom>{description}</Typography>}
        <Select
          displayEmpty
          value={selected !== title ? selected : ''}
          onChange={e => setSelected(e.target.value)}>
          <MenuItem value=''>
            <em>Select</em>
          </MenuItem>
          {dropdownItems.map((item, idx) => (
            <MenuItem key={idx} value={item.value}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onReset(title)} color='secondary'>
          Reset
        </Button>
        <Button
          variant='contained'
          onClick={() => onConfirm(selected === title ? '' : selected)}
          color='secondary'>
          Confirm
        </Button>
      </DialogActions>
    </EditableDialogWrapper>
  )
}

DropdownDialog.propTyes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
  dropdownItems: PropTypes.array.isRequired,
}

export default DropdownDialog
