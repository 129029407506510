import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import AdminCardHeader from './adminCardHeader'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  cardComponent: { marginBottom: '20px' },
})

const AdminCard = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Card className={classes.cardComponent}>
      <CardContent style={{ padding: 16 }}>
        {title && <AdminCardHeader title={title} />}
        {children}
      </CardContent>
    </Card>
  )
}

export default AdminCard
