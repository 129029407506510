import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import AdminBackdrop from '../../../components/adminBackdrop'
import AdminPageHeader from '../../../components/adminPageHeader'

import {
  getEditableRegionInputTypeDescription,
  getEditableRegionGroupById,
  getEditableRegions,
  getMergeFields,
} from '../../../services/editableregion.service'
import { useService } from '../../../contexts/serviceContext'
import { stringCompare } from '../../../utils/sortHelper'

const EditableRegionGroupEdit = () => {
  let history = useHistory()
  let { id } = useParams()
  const { serviceId } = useService()

  const [isLoading, setIsLoading] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [editableRegionGroup, setEditableRegionGroup] = useState(null)
  const [mergeFields, setMergeFields] = useState(null)

  const [isLoadingList, setIsLoadingList] = useState(false)  
  const [editableRegions, setEditableRegions] = useState([])

  const [filterInputTypeId, setfilterInputTypeId] = useState()
  const [filterText, setFilterText] = useState('')
  const [filteredAndSorted, setFilteredAndSorted] = useState([])
  const [filteredAndSortedArchived, setFilteredAndSortedArchived] = useState([])
  const [includeArchived, setIncludeArchived] = useState(false)
  const [direction, setDirection] = useState('asc')
  

  useEffect(() => {
    getMergeFields().then(res => setMergeFields(res))
    getEditableRegionGroupById(parseInt(id)).then(result => setEditableRegionGroup(result))
  }, [id, serviceId])

  useEffect(() => {
    setIsLoadingList(true)
    getEditableRegions(serviceId).then(result => {
      setIsLoadingList(false)
      setEditableRegions(result)
      console.log(result)
    })
  }, [serviceId])

  useEffect(() => {
    setIsLoading(!mergeFields || !editableRegionGroup)
  }, [mergeFields, editableRegionGroup])

  useEffect(() => {
    var filteredAndSorted = editableRegions
      .filter(
        er =>
          (er.inputTypeId === filterInputTypeId || filterInputTypeId === 0) &&
          (er.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
            filterText === '')
      )
      .sort((a, b) => stringCompare(a.name, b.name, direction))
    setFilteredAndSorted(filteredAndSorted.filter(er => !er.isArchived))
    setFilteredAndSortedArchived(filteredAndSorted.filter(er => er.isArchived))
  }, [editableRegions, filterInputTypeId, filterText, direction])



  return (
    <>
      <AdminBackdrop show={isLoading || isUpdating} />
      {!isLoading && !isUpdating && editableRegionGroup ? (
        <>
          <AdminPageHeader
            title={`Update Editable ${editableRegionGroup.name} Group`}
            navActionLabel='Return to editables'
            navActionPath='/admin/editable'
          />
          <List dense>
                      {filteredAndSorted.map(er => (
                        <ListItem
                          key={er.id}
                          button
                          
                          to={`/admin/editable/${er.id}`}>
                          <ListItemText
                            primary={er.name}
                            secondary={getEditableRegionInputTypeDescription(
                              er.inputTypeId
                            )}
                          />
                        
                        </ListItem>
                      ))}
                    </List>
        </>
      ) : null}
    </>
  )
}

export default EditableRegionGroupEdit
