import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Typography } from '@material-ui/core'
import EditableDialogWrapper from './editableDialogWrapper'
import PropTypes from 'prop-types'
import moment from 'moment'
import { KeyboardDatePicker } from '@material-ui/pickers'

const DatePickerDialog = ({
  title,
  description,
  open,
  onConfirm,
  onReset,
  onClose,
  currentDate,
}) => {
  const [date, setDate] = useState(
    new moment(currentDate, 'DD/MM/YYYY').isValid()
      ? new moment(currentDate, 'DD/MM/YYYY')
      : new moment()
  )

  return (
    <EditableDialogWrapper title={title} open={open} onClose={onClose}>
      <DialogContent dividers>
        {description && <Typography gutterBottom>{description}</Typography>}
        <KeyboardDatePicker
          format='DD/MM/YYYY'
          value={date}
          onChange={selectedDate => setDate(selectedDate)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onReset(new moment())} color='secondary'>
          Reset
        </Button>
        <Button
          variant='contained'
          onClick={() => onConfirm(date)}
          color='secondary'>
          Confirm
        </Button>
      </DialogActions>
    </EditableDialogWrapper>
  )
}

DatePickerDialog.propTyes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentDate: PropTypes.number.isRequired,
}

export default DatePickerDialog
