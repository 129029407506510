export const dateTimeConstants = {
    KEYBOARD_PICKER_DATE_FORMAT: 'dd/MM/yyyy',
    KEYBOARD_PICKER_DATE_TIME_FORMAT: 'dd/MM/yyyy HH:mm',
    PICKER_DATE_TIME_FORMAT: 'dd-MMM-yyyy HH:mm',
    PICKER_DATE_FORMAT: 'dd-MMM-yyyy',
    THREE_MONTH_FORMAT: 'dd MMM yyyy',
}
export const dateTimeFormatOptionsConstants = {
    DEFAULT_DATE_TIME: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    },
    DATE_ONLY: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    },
}