import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

function getAdminServices() {
  const url = `${window.config.DOCUMENT_API_URL}/v1/servicemapping/getall`
  return httpClient.get(url).then(
    response => handleServiceResponse(response),
    error => handleServiceError(error)
  ) 
}

export default {
  getAdminServices,
}
