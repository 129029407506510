import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

function getService(serviceId) {
  return httpClient
    .get(`${window.config.CONFIG_API_URL}/api/services/${serviceId}`)
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

export default {
  getService,
}
