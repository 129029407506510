import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getServiceBranding } from '../../actions/branding.actions'
import { getService } from '../../actions/service.actions'
import LoadingPage from '../loadingPage'

const ThemeLoader = ({ children }) => {
  const dispatch = useDispatch()
  const { isLoadingTheme, theme } = useSelector(state => state.branding)

  useEffect(() => {
    if (theme === null) {
      dispatch(getServiceBranding())
    }
  }, [dispatch, theme])

  const userServiceId = useSelector(
    state => state.auth.user.profile['service_id']
  )

  useEffect(() => {
    if (userServiceId) {
      dispatch(getService(userServiceId))
    }
  }, [userServiceId])

  return isLoadingTheme ? <LoadingPage text='Loading theme' /> : children
}

export default ThemeLoader
