import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import EditableRegionForm from '../../../components/editableRegionForm'
import AdminBackdrop from '../../../components/adminBackdrop'
import AdminPageHeader from '../../../components/adminPageHeader'

import {
  addEditableRegion,
  getMergeFields,
  getEditableRegions,
} from '../../../services/editableregion.service'
import { useService } from '../../../contexts/serviceContext'

const EditableRegionAdd = () => {
  let history = useHistory()
  const { serviceId } = useService()

  const [initialData] = useState({
    id: 0,
    serviceId: serviceId,
    inputTypeId: 0,
    name: '',
    minLength: 1,
    maxLength: 50,
    mergeFieldId: 0,
    editableRegionOptions: [],
    isMandatory: true,
    isArchived: false,
  })

  const [isLoading, setIsLoading] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const [mergeFields, setMergeFields] = useState(null)
  const [currentEditables, setCurrentEditables] = useState(null)

  useEffect(() => {
    getMergeFields().then(res => setMergeFields(res))
    getEditableRegions(serviceId).then(res => setCurrentEditables(res))
  }, [serviceId])

  useEffect(() => setIsLoading(!mergeFields || !currentEditables), [
    mergeFields,
    currentEditables,
  ])

  const onSubmit = newEditableRegion => {
    setIsCreating(true)
    addEditableRegion(newEditableRegion).then(() => {
      setIsCreating(false)
      history.push('/admin/editable')
    })
  }

  return (
    <>
      <AdminBackdrop show={isCreating || isLoading} />
      {!isCreating && !isLoading ? (
        <>
          <AdminPageHeader
            title={'Create Editable'}
            navActionLabel='Return to editables'
            navActionPath='/admin/editable'
          />
          <EditableRegionForm
            editableRegion={initialData}
            mergeFields={mergeFields}
            currentEditables={currentEditables}
            onSubmit={onSubmit}
          />
        </>
      ) : null}
    </>
  )
}

export default EditableRegionAdd
