import { GET_SERVICE_SUCCESS } from '../actions/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState = null

export default createReducer(initialState, {
  [GET_SERVICE_SUCCESS]: (state, action) => {
    return action.response
  },
})
