import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const HtmlEditor = ({
    title,
    description,
    initialHtml,
    onChange,
    fontSize,
    fontFamily,
}) => {
    const [html, setHtml] = useState(initialHtml);
    const contentEditorWrapperRef = useRef();
    const contentEditorRef = useRef();
    const editorInitialized = useRef(false);  // Use ref to avoid re-initialization

    const onContentEditorChange = () => {
        let $ = window.jQuery;
        const editor = $(contentEditorRef.current).data('kendoEditor');
        if (editor) {
            const newHtml = editor.value();
            setHtml(newHtml);
            if (onChange) {
                onChange(newHtml);
            }
        }
    };

    useEffect(() => {
        if (!editorInitialized.current) {
            let $ = window.jQuery;

            $(contentEditorRef.current).kendoEditor({
                stylesheets: [
                    `/css/editor/font-family-${fontFamily}.css`,
                    `/css/editor/font-size-${fontSize}.css`,
                ],
                tools: [
                    'bold', 'italic', 'underline', 'justifyLeft', 'justifyCenter',
                    'justifyRight', 'justifyFull', 'insertUnorderedList',
                    'insertOrderedList', 'indent', 'outdent', 'insertImage', 'tableWizard',
                    'createTable', 'addRowAbove', 'addRowBelow', 'addColumnLeft',
                    'addColumnRight', 'deleteRow', 'deleteColumn', 'mergeCellsHorizontally',
                    'mergeCellsVertically', 'splitCellHorizontally', 'splitCellVertically',
                    'viewHtml', 'formatting', 'cleanFormatting', 'copyFormat', 'applyFormat',
                    'foreColor', 'backColor', 'fontName', 'fontSize',
                ],
                change: onContentEditorChange,
            });

            const editor = $(contentEditorRef.current).data('kendoEditor');
            if (editor) {
                editor.value(html);
            }

            editorInitialized.current = true;  // Mark editor as initialized
            contentEditorWrapperRef.current.style.display = 'block';
        }
    }, [fontSize, fontFamily]);  // Run only when font size or family changes

    useEffect(() => {
        let $ = window.jQuery;
        const editor = $(contentEditorRef.current).data('kendoEditor');
        if (editor) {
            editor.value(html);
        }
    }, [html]);

    return (
        <div>
            {title && <Typography variant="h6">{title}</Typography>}
            {description && <Typography gutterBottom>{description}</Typography>}
            <div style={{ height: 400 }}>
                <div ref={contentEditorWrapperRef} style={{ display: 'none' }}>
                    <textarea style={{ height: 380 }} ref={contentEditorRef} />
                </div>
            </div>
        </div>
    );
};

HtmlEditor.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    initialHtml: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
};

export default HtmlEditor;
