import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: { margin: '8px 0 24px 0' },
})

const AdminCardHeader = ({ title }) => {
  const classes = useStyles()

  return <h2 className={classes.title}>{title}</h2>
}

export default AdminCardHeader
