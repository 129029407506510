import React from 'react'

import { Grid, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  headerContainer: {
    paddingTop: 16,
    marginBottom: 16,
  },
  title: {
    margin: 0,
  },
  controlContent: {
    textAlign: 'right',
    '& a': {
      margin: 0,
    },
  },
})

const AdminPageHeader = ({ title, navActionLabel, navActionPath }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.headerContainer}>
      <Grid item xs={12} sm={6}>
        <h1 className={classes.title}>{title}</h1>
      </Grid>
      {navActionLabel && navActionPath && (
        <Grid item xs={12} sm={6} className={classes.controlContent}>
          <Button component={Link} to={navActionPath} variant='outlined'>
            {navActionLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default AdminPageHeader
