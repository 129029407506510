import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminCard from '../../../components/adminCard'
import AdminCardHeader from '../../../components/adminCardHeader'
import AdminBackdrop from '../../../components/adminBackdrop'
import SortButton from '../../../components/sortButton'
import documentBrandingService from '../../../services/documentbranding.service'
import { useService } from '../../../contexts/serviceContext'
import { stringCompare } from '../../../utils/sortHelper'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: '20px',
    width: 200,
  },
  submitControl: {
    marginTop: '20px',
  },
  arrowIcon: {
    color: theme.palette.primary.main,
  },
}))

const DocumentBrandingList = () => {
  const classes = useStyles()
  const { serviceId } = useService()

  const [isLoadingList, setIsLoadingList] = useState(false)
  const [documentBrandings, setDocumentBrandings] = useState([])

  const [filterText, setFilterText] = useState('')
  const [filteredAndSorted, setFilteredAndSorted] = useState([])
  const [filteredAndSortedArchived, setFilteredAndSortedArchived] = useState([])
  const [includeArchived, setIncludeArchived] = useState(false)

  useEffect(() => {
    setIsLoadingList(true)
    documentBrandingService.getDocumentBrandings(serviceId).then(result => {
      setIsLoadingList(false)
      setDocumentBrandings(result)
    })
  }, [serviceId])

  const [direction, setDirection] = useState('asc')

  useEffect(() => {
    if (!documentBrandings) return
    var filteredAndSorted = documentBrandings
      .filter(
        db =>
          db.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
          filterText === ''
      )
      .sort((a, b) => stringCompare(a.name, b.name, direction))
    setFilteredAndSorted(filteredAndSorted.filter(db => !db.isArchived))
    setFilteredAndSortedArchived(filteredAndSorted.filter(db => db.isArchived))
  }, [documentBrandings, filterText, direction])

  return (
    <>
      <AdminBackdrop show={isLoadingList} />

      {!isLoadingList ? (
        <>
          <AdminPageHeader
            title={'Document Branding'}
            navActionLabel='Create New'
            navActionPath='/admin/branding/add'
          />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AdminCard>
                <AdminCardHeader title={'Filter'} />
                <div style={{ marginBottom: 20 }}>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 10 }}>
                    <TextField
                      type='text'
                      label='Name'
                      value={filterText}
                      onChange={e => setFilterText(e.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includeArchived}
                          onChange={e => setIncludeArchived(e.target.checked)}
                          color='primary'
                        />
                      }
                      label='Include Archived?'
                    />
                  </FormControl>
                </div>
                <AdminCardHeader title={'Existing Brandings'} />
                {filteredAndSorted && filteredAndSorted.length > 0 && (
                  <SortButton
                    direction={direction}
                    onDirectionChange={setDirection}
                  />
                )}
                {!isLoadingList && includeArchived ? <h3>Current:</h3> : null}
                {!isLoadingList ? (
                  filteredAndSorted.length > 0 ? (
                    <List dense>
                      {filteredAndSorted.map(db => (
                        <ListItem
                          key={db.id}
                          button
                          component={Link}
                          to={`/admin/branding/${db.id}`}>
                          <ListItemText primary={db.name} />
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <p>No brandings</p>
                  )
                ) : null}
                {!isLoadingList && includeArchived ? <h3>Archived:</h3> : null}
                {!isLoadingList && includeArchived ? (
                  filteredAndSortedArchived.length > 0 ? (
                    <List dense>
                      {filteredAndSortedArchived.map(db => (
                        <ListItem
                          key={db.id}
                          button
                          component={Link}
                          to={`/admin/branding/${db.id}`}>
                          <ListItemText primary={db.name} />
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <p>No archived brandings</p>
                  )
                ) : null}
              </AdminCard>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  )
}

export default DocumentBrandingList
