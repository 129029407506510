import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminBackdrop from '../../../components/adminBackdrop'
import DocumentTemplateForm from '../../../components/documentTemplateForm'

import templateService from '../../../services/documenttemplate.service'
import brandingService from '../../../services/documentbranding.service'
import { getEditableRegions } from '../../../services/editableregion.service'
import { useService } from '../../../contexts/serviceContext'
import reviewTypeService from '../../../services/reviewType.service'
import reviewTypeConstants from '../../../constants/reviewType.constants'

const DocumentTemplateAdd = () => {
  let history = useHistory()
  const { serviceId } = useService()

  const [isLoading, setIsLoading] = useState(false)
  const [brandings, setBrandings] = useState(null)
  const [currentTemplates, setCurrentTemplates] = useState(null)
  const [editables, setEditables] = useState(null)
  const [reviewTypes, setReviewTypes] = useState([])
  const [initialFormData] = useState({
    id: 0,
    name: '',
    serviceId: serviceId,
    isArchived: false,
    brandingId: 0,
    contentHtml: "",
    adminContentHtml: "",
    ReviewType: reviewTypeConstants.NoReview,
    isAnytime: false,
    isVisibleToProcessEngineBusinessUser: false,
  })

  useEffect(() => {
    brandingService
      .getDocumentBrandings(serviceId)
      .then(res => setBrandings(res))

    templateService
      .getDocumentTemplates(serviceId)
      .then(res => setCurrentTemplates(res))

      reviewTypeService
      .getReviewTypes()
      .then(reviewTypes=> setReviewTypes(reviewTypes))

    getEditableRegions(serviceId).then(regions => setEditables(regions))
  }, [serviceId])
  
  useEffect(() => {
    setIsLoading(!brandings || !editables || !currentTemplates || !reviewTypes)
  }, [brandings, editables, currentTemplates, reviewTypes])

  const handleSubmit = updateFormData => {
    setIsLoading(true)
    templateService.addDocumentTemplate(updateFormData).then(() => {
      setIsLoading(false)
      history.push('/admin/template')
    })
  }

  return (
    <>
      <AdminBackdrop show={isLoading} />
      {!isLoading ? (
        brandings && editables && currentTemplates && reviewTypes? (
          <>
            <AdminPageHeader
              title={'Add Template'}
              navActionLabel='Return to templates'
              navActionPath='/admin/template'
            />
            <DocumentTemplateForm
              initialFormData={initialFormData}
              currentTemplates={currentTemplates}
              brandings={brandings}
              editables={editables}
              reviewTypes={reviewTypes}
              onSubmit={handleSubmit}
            />
          </>
        ) : null
      ) : null}
    </>
  )
}

export default DocumentTemplateAdd
